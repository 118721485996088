<template>
    <div class="container1150">
        <header><strong>运维管理平台</strong></header>
        <main>
            <h2>● · 易排查</h2>
            <div class="img3"></div>
            <div class="img4"></div>
            <div class="img5"></div>
            <div class="img6"></div>
            <h2>● · 面源污染整治系统</h2>
            <p>· 功能满足水务局和生态局的考核要求</p>
            <p>
                ·
                商户申请，外业勘察，内业审核，备案表发送均在手机小程序/APP端完成
            </p>
            <p>
                ·
                排水户空间动态管理，在排水管网一张图的基础上，建立排水户跟排水管网之间的拓扑关系
            </p>
            <p>
                ·
                支持台账各种自定义组合统计一键式导出，比起传统的表单管理，可以做到排水户全覆盖，无遗漏，精准定位管理
            </p>
            <div class="img1"></div>
            <div class="img2"></div>
            <h2>● · 污水零直排问题协调机制小程序</h2>
            <div class="img7"></div>
            <h2>● · 管网运维统计平台</h2>
            <div class="img8"></div>
            <!-- <div class="img-list">
                <div class="img1"></div>
                <div class="img2"></div>
            </div> -->
            <!-- <p>● · 滕州市城区排水管网智慧平台</p>
            <div class="img1"></div>
            <p>● · 合流制截排系统只能调度管控平台（坪山）</p>
            <div class="img2"></div>
            <p>● · 顺德区流域治理一体化数字平台</p>
            <div class="img3"></div> -->
        </main>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
};
</script>
<style lang="scss" scoped>
.container1150 {
    width: 1150px;
    header {
        height: 70px;
        line-height: 70px;
        text-align: center;
        font-size: 26px;
    }
    main {
        p {
            // height: 20px;
            font-size: 16px;
            line-height: 30px;
        }
        h2 {
            height: 60px;
            line-height: 60px;
        }
        .text-img {
            display: flex;
            justify-content: space-around;
            p {
                width: 40%;
                padding: 50px 0 0 20px;
                box-sizing: border-box;
            }
            .img1 {
                // width: 100%;
                width: 500px;
                height: 300px;
                margin: -60px auto 0;
                background-image: url("/img/product/6-3.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }

        .img-list {
            display: flex;
            width: 100%;
            .img1 {
                // width: 100%;
                width: 550px;
                height: 450px;
                margin: 10px auto;
                background-image: url("/img/product/7-1.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .img2 {
                // width: 100%;
                width: 550px;
                height: 450px;
                margin: 10px auto;
                background-image: url("/img/product/7-2.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
        .img1 {
            // width: 100%;
            width: 900px;
            height: 400px;
            margin: 10px auto;
            background-image: url("/img/product/15-1.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img2 {
            width: 800px;
            height: 1200px;
            margin: 10px auto;
            background-image: url("/img/product/15-2.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img3 {
            width: 1100px;
            height: 500px;
            margin: 10px auto;
            background-image: url("/img/product/15-3.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img4 {
            width: 1100px;
            height: 500px;
            margin: 10px auto;
            background-image: url("/img/product/15-4.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img5 {
            width: 1100px;
            height: 500px;
            margin: 10px auto;
            background-image: url("/img/product/15-5.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img6 {
            width: 1100px;
            height: 500px;
            margin: 10px auto;
            background-image: url("/img/product/15-6.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img7 {
            width: 1100px;
            height: 500px;
            margin: 10px auto;
            background-image: url("/img/product/15-7.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img8 {
            width: 1100px;
            height: 550px;
            margin: 10px auto;
            background-image: url("/img/product/15-8.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
    }
}
</style>